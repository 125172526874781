import React, { useState, useMemo, useEffect, useRef } from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { toast } from "react-toastify";
import { debounce } from "lodash";
// reactstrap components
import {
  Table as RTable,
  Input as RInput,
  Col,
  Label,
  Row,
  Button,
} from "reactstrap";
// lida com as requisições
import { api } from "services/api";
// lidar com criptografias MD5
// eslint-disable-next-line no-undef
const md5 = require("md5");
//funçoes globais
import { formatValue } from "services/globals";
// carrega o Header dos produtos que está separado para facilitar a vida na leitura do código
import getColumnsHeader from "./HeaderProdutos";
import ModalLoading from "components/Loading/ModalLoading";
import { decode as base64_decode } from "base-64";
import { useProductContext } from "../../../contexts/ProdutosSimuladorContext";
import { useSimuladorContext } from "./../../../contexts/SimuladorContext";
import { LogAirbrake } from "services/log.airbrake";
import { proxyApi } from "services/proxyApi";

function ProdutosListagem(props) {
  const { handleUpdateSimuladorCliente, dataSimulador, setDataSimulador } =
    useSimuladorContext();

  const {
    dataProducts: data,
    setDataProducts: setData,
    m3Cliente,
    setM3Cliente,
    m3Simulador,
    setM3Simulador,
    vlrFaturado,
    setVlrFaturado,
  } = useProductContext();

  useEffect(() => {
    // console.log("dataProducts---", dataProducts);
    const hasProductChanged = data.filter((product) => product.updated);

    if (hasProductChanged.length > 0) {
      if (dataSimulador?.IMC_CLI != "0") {
        setDataSimulador((state) => ({ ...state, IMC_CLI: "0" }));
      }
    }
  }, [data]);

  useEffect(() => {
    const debouncedFastSubmit = debounce(fastSubmit, 2000);
    debouncedFastSubmit();
    return () => {
      debouncedFastSubmit.cancel();
    };
  }, [data]);

  const [modalLoading, setModalLoading] = useState(true);
  const columns = useMemo(() => [getColumnsHeader], []);
  const idUsuario = sessionStorage.getItem(md5("CODUSU"));
  const codVend = base64_decode(sessionStorage.getItem(md5("CODVEND")));
  const {
    getCabStatusSimulacao,
    setCabResume,
    //////validation
    setCabValidacaoProduto,
    setCabValidacaoCliente,
    setCabValidacaoFrete,
    setCabValidacaoVerba,
  } = props;

  // const refs = useRef(data.map(() => React.createRef()));
  const refs = useRef([]);
  refs.current = new Array(data.length);

  useEffect(() => {
    const fetchData = async () => {
      if (props.getCabNuSimCli > 0) {
        setModalLoading(true);
        try {
          const response = await proxyApi.get(
            `simulador-item/${idUsuario}/${props.getCabNuSim}/${props.getCabNuSimCli}/0`
          );

          // console.log("response.data:", response.data);

          if (response.data?.statusApi == 1) {
            const {
              simuladorIte,
              M3_CLI,
              M3_SIM,
              VLR_FATURAMENTO,
              resume,
              validaCliente,
              validaFrete,
              validaProdutos,
              validaVerbas,
            } = response.data;
            // //console.log(response.data)
            handleValidation(
              validaCliente,
              validaFrete,
              validaProdutos,
              validaVerbas
            );
            handleStates(simuladorIte, M3_CLI, M3_SIM, VLR_FATURAMENTO, resume);
          } else {
            // console.error(response.data);
            toast.error(response.data.message, {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            });
          }
          setModalLoading(false);
        } catch (error) {
          toast.error(
            `Não foi possível conectar ao servidor, atualize a página e tente novamente. ${error.message}`,
            {
              autoClose: 7000,
              icon: () => <i className="tim-icons icon-simple-remove" />,
            }
          );
          console.error("error: ", error);
          setModalLoading(false);
        }
      }
    };
    fetchData();
  }, [props.getCabNuSimCli]);

  data.sort((productCurr, productPrev) =>
    productCurr.DESCRPROD < productPrev.DESCRPROD ? -1 : 1
  );

  // console.log("-->", teste);

  const fastSubmit = async () => {
    let raw = {
      nuSim: props.getCabNuSim,
      nuSimCli: props.getCabNuSimCli,
      produtos: data.map((product) => ({
        ...product,
        QTDNEG: Number(product.QTDNEG),
        PRECONEG: Number(product.PRECONEG),
        M3: Number(product.M3),
        M3TOTAL: Number(product.M3TOTAL),
        PESOBRUTO: Number(product.PESOBRUTO),
        PESOBRUTOTOTAL: Number(product.PESOBRUTOTOTAL),
        CUSTOUNPRODUCAO: Number(product.CUSTOUNPRODUCAO),
        MC: Number(product.MC),
        MCT: Number(product.MCT),
        IMC_ITEM: Number(product.IMC_ITEM),
        PRECOMIN: Number(product.PRECOMIN),
        PRECOMAX: Number(product.PRECOMAX),
        VLRTABPADRAO: Number(product.VLRTABPADRAO),
        VLRTAB: Number(product.VLRTAB),
        VLRTABTRANSF: Number(product.VLRTABTRANSF),
        VLRTOTAL: Number(product.VLRTOTAL),
        PERCRCA: Number(product.PERCRCA),
        PERCRCAMAX: Number(product.PERCRCAMAX),
        VLRCOMISSAORCA: Number(product.VLRCOMISSAORCA),
        CREDICMS_PER: Number(product.CREDICMS_PER),
        CREDICMS_TOTAL: Number(product.CREDICMS_TOTAL),
        DEBICMS_TOTAL: Number(product.DEBICMS_TOTAL),
        DEBICMS_PER: Number(product.DEBICMS_PER),
        NUSIMITE: Number(product.NUSIMITE),
        NUSIM: Number(product.NUSIM),
        NUSIMCLI: Number(product.NUSIMCLI),
        CODPROD: Number(product.CODPROD),
        CODGRUPAI: Number(product.CODGRUPAI),
      })),
      codVend: codVend,
    };

    try {
      const response = await api.put(`simulador-item/fast/${idUsuario}`, raw);

      if (response.data?.statusApi == 1) {
        console.log(response.data);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setModalLoading(true);

    localStorage.setItem(
      "cache-produtos",
      JSON.stringify(data.filter((product) => Number(product.QTDNEG) > 0))
    );

    let raw = {
      nuSim: props.getCabNuSim,
      nuSimCli: props.getCabNuSimCli,
      produtos: data.map((product) => ({
        ...product,
        QTDNEG: Number(product.QTDNEG),
        PRECONEG: Number(product.PRECONEG),
        M3: Number(product.M3),
        M3TOTAL: Number(product.M3TOTAL),
        PESOBRUTO: Number(product.PESOBRUTO),
        PESOBRUTOTOTAL: Number(product.PESOBRUTOTOTAL),
        CUSTOUNPRODUCAO: Number(product.CUSTOUNPRODUCAO),
        MC: Number(product.MC),
        MCT: Number(product.MCT),
        IMC_ITEM: Number(product.IMC_ITEM),
        PRECOMIN: Number(product.PRECOMIN),
        PRECOMAX: Number(product.PRECOMAX),
        VLRTABPADRAO: Number(product.VLRTABPADRAO),
        VLRTAB: Number(product.VLRTAB),
        VLRTABTRANSF: Number(product.VLRTABTRANSF),
        VLRTOTAL: Number(product.VLRTOTAL),
        PERCRCA: Number(product.PERCRCA),
        PERCRCAMAX: Number(product.PERCRCAMAX),
        VLRCOMISSAORCA: Number(product.VLRCOMISSAORCA),
        CREDICMS_PER: Number(product.CREDICMS_PER),
        CREDICMS_TOTAL: Number(product.CREDICMS_TOTAL),
        DEBICMS_TOTAL: Number(product.DEBICMS_TOTAL),
        DEBICMS_PER: Number(product.DEBICMS_PER),
        NUSIMITE: Number(product.NUSIMITE),
        NUSIM: Number(product.NUSIM),
        NUSIMCLI: Number(product.NUSIMCLI),
        CODPROD: Number(product.CODPROD),
        CODGRUPAI: Number(product.CODGRUPAI),
      })),
      codVend: codVend,
    };

    // console.log("produtosListagem:", raw);

    try {
      const response = await api.put(`simulador-item/${idUsuario}`, raw);

      // console.log("@@##", raw, response);

      if (response.data?.statusApi == 1) {
        const {
          simuladorIte,
          imc_cli,
          M3_CLI,
          M3_SIM,
          VLR_FATURAMENTO,
          resume,
          validaCliente,
          validaFrete,
          validaProdutos,
          validaVerbas,
        } = response.data;
        handleValidation(
          validaCliente,
          validaFrete,
          validaProdutos,
          validaVerbas
        );
        await handleUpdateSimuladorCliente();

        handleStates(simuladorIte, M3_CLI, M3_SIM, VLR_FATURAMENTO, resume);

        //console.log(props.setCabImcGeral);
      } else {
        console.error(response.data);
        toast.error(response.data.message, {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        });
      }
    } catch (error) {
      console.log(error.response);

      LogAirbrake(error, {
        url: `simulador-item/${idUsuario}`,
        method: "PUT",
        data: raw,
      });

      toast.error(
        `Não foi possível conectar ao servidor, atualize a página e tente novamente. xSMITE/hs ${error.message}`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
      console.error("error: ", error);
    }
    setModalLoading(false);
  };

  const handleValidation = (
    validaCliente,
    validaFrete,
    validaProdutos,
    validaVerbas
  ) => {
    // console.log("validaFrete@", validaFrete);
    setCabValidacaoProduto(validaProdutos);
    setCabValidacaoCliente(validaCliente);
    setCabValidacaoFrete(validaFrete);
    setCabValidacaoVerba(validaVerbas);
  };

  useEffect(() => {
    props.setCabImcGeral(dataSimulador?.IMC_CLI);
  }, [dataSimulador]);

  const handleStates = async (
    simulador_ite,
    m3_cli,
    m3_sim,
    vlr_faturado,
    resume
  ) => {
    // const response = await api.get(
    //   `simulador-cliente/${idUsuario}/${base64_decode(
    //     sessionStorage.getItem(md5("SIMULADOR_NUSIMCLI"))
    //   )}`,
    //   { codParc: props.getCabCodParc }
    // );

    // console.log("aqui1--->");

    // if (response.data?.statusApi === 1) {
    //   props.setCabImcGeral(response.data.simuladorCli.IMC_CLI);
    //   // console.log("produtoListagemSimuladorCliente:", response);
    // }

    setData(simulador_ite);
    setM3Cliente(m3_cli);
    setM3Simulador(m3_sim);
    setVlrFaturado(vlr_faturado);
    setCabResume(resume);

    if (m3_sim >= 80) {
      toast.warn("Metragem maior que 80m³.", {
        autoClose: 7000,
        icon: () => <i className="tim-icons icon-simple-remove" />,
      });
    } else {
      toast.warn(
        `Faltam ${formatValue(
          "decimal",
          80 - m3_sim
        )} para fechar a carga de um caminhão 80m³.`,
        {
          autoClose: 7000,
          icon: () => <i className="tim-icons icon-simple-remove" />,
        }
      );
    }
  };

  const calculaTotal = (quantidade, vlrNeogicado, precoMin, precoMax) => {
    let vlrTotal = 0;
    quantidade = parseInt(quantidade);
    vlrNeogicado = parseFloat(vlrNeogicado);
    precoMin = parseFloat(precoMin);
    precoMax = parseFloat(precoMax);
    if (quantidade >= 0) {
      if (vlrNeogicado >= precoMin && vlrNeogicado <= precoMax) {
        vlrTotal = quantidade * vlrNeogicado;
      }
      // //console.log('No quantidade >0',quantidade, vlrNeogicado, precoMin, precoMax);
    }
    // //console.log('No calculo',quantidade, vlrNeogicado, precoMin, precoMax);
    return vlrTotal;
  };

  // Precisamos evitar que a tabela reinicie o pageIndex quando
  // Atualizar dados. Assim, podemos acompanhar essa bandeira com um ref.
  // Quando nosso renderizador de célula chamar updateMyData, usaremos
  // o rowIndex, columnId e o novo valor para atualizar o
  // dados originais
  const updateMyData = (rowIndex, columnId, value) => {
    // Também ativamos o sinalizador para não redefinir a página
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          let newValue = null;
          let vlrTotal = null;

          // if(id=='PERCRCA') {
          //   newValue = (value > 0)?value:0;
          // } // verificar percentual maximo do RCA
          if (columnId == "PRECONEG") {
            //preco negociado não pode ser menor que o valor mínimo e nem maior que o valor máximo
            if (
              Number(value) >= row.PRECOMIN &&
              Number(value) <= row.PRECOMAX
            ) {
              newValue = value;
              vlrTotal = calculaTotal(
                row.QTDNEG,
                newValue,
                row.PRECOMIN,
                row.PRECOMAX
              );
            } else {
              newValue = Number(row.VLRTAB).toFixed(2);
              vlrTotal = calculaTotal(
                row.QTDNEG,
                newValue,
                row.PRECOMIN,
                row.PRECOMAX
              );
              toast.error(
                `Valor Negociado do produto "${row.DESCRPROD}" deve ser maior que R$${row.PRECOMIN} e menor que R$${row.PRECOMAX}.\nValor informado: ${value}`
              );
            }
          } else if (columnId == "QTDNEG") {
            newValue = value;
            vlrTotal = calculaTotal(
              newValue,
              row.PRECONEG,
              row.PRECOMIN,
              row.PRECOMAX
            );
          } else if (columnId == "PERCRCA") {
            newValue = value;
            vlrTotal = calculaTotal(
              row.QTDNEG,
              row.PRECONEG,
              row.PRECOMIN,
              row.PRECOMAX
            );
          }
          if (vlrTotal >= 0) {
            return {
              ...old[rowIndex],
              [columnId]:
                columnId == "QTDNEG"
                  ? parseInt(newValue)
                  : Number(newValue).toFixed(2),
              VLRTOTAL: Number(vlrTotal).toFixed(2),
              updated: true,
            };
          } else {
            return {
              ...old[rowIndex],
              [columnId]: Number(newValue),
              updated: true,
            };
          }
        }
        return row;
      })
    );
    // props.setProdutosCarregados(data);
  };

  // Definir uma IU padrão para filtragem
  function DefaultColumnFilter({ column: { filterValue, setFilter, id } }) {
    // const count = preFilteredRows.length;
    let texto = "";
    let type = "text";
    switch (id) {
      case "CODPROD":
        texto = "Código do produto";
        type = "number";
        break;
      case "DESCRPROD":
        texto = "Nome do produto";
        type = "text";
        break;
    }
    return (
      <RInput
        value={filterValue || ""}
        type={type}
        id={"filter_" + id}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Defina undefined para remover o filtro completamente
        }}
        placeholder={texto}
      />
    );
  }

  // Cria um renderizador de célula editável
  const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    updateMyData, // Esta é uma função personalizada que fornecemos para nossa instância de tabela
  }) => {
    // Precisamos manter e atualizar o estado da célula normalmente
    const [value, setValue] = useState(initialValue);

    const onChange = (e) => {
      let newValue = null;
      let { value } = e.target;

      if (value == "" && id == "QTDNEG") {
        setValue("");
      }
      if (value !== null) {
        if (value >= 0) {
          newValue = id == "QTDNEG" ? parseInt(value) : Number(value);
          if (newValue > 99999) {
            toast.error(`O valor informado de ve ser menor que 100.000`);
            return;
          }
          setValue(newValue);
        } else {
          toast.error(`Valor informado deve ser MAIOR que ZERO`);
        }
      }
    };

    // Só atualizaremos os dados externos quando a entrada estiver fora de foco (onBlur)

    const onBlur = (e) => {
      let newValue = null;
      if (value !== "" && value !== null) {
        if (value >= 0) {
          newValue = id == "QTDNEG" ? parseInt(value) : value;
          updateMyData(index, id, newValue);
        } else {
          toast.error(`Valor informado deve ser MAIOR que ZERO`);
        }
      }
      // refs.current[5].focus();
      const objectName = e.target?.name;
      if (!objectName) {
        return;
      }

      const currentName = objectName.split("-")[0];
      const currentIDX = objectName.split("-")[1];

      let nextName = "QTDNEG";
      let nextIDX = currentIDX;

      if (currentName === "QTDNEG") {
        nextName = "PRECONEG";
      } else if (currentName === "PRECONEG") {
        nextName = "PERCRCA";
      } else {
        nextIDX++;
      }

      const supposedNext = e.nativeEvent.relatedTarget?.name;

      if (!supposedNext && nextName !== "QTDNEG") {
        return;
      }

      setTimeout(() => {
        if (supposedNext === `${nextName}-${nextIDX}` || supposedNext === "") {
          document.getElementsByName(`${nextName}-${nextIDX}`)[0]?.focus();
        } else {
          document.getElementsByName(supposedNext)[0]?.focus();
        }
      }, 200);
    };

    // Se o initialValue for alterado externamente, sincronize-o com o nosso estado
    useEffect(() => {
      // //console.log(id);
      setValue(initialValue);
    }, [initialValue]);
    if (getCabStatusSimulacao != 0) {
      if (
        value !== null &&
        (id == "QTDNEG" ||
          id == "PERCRCA" ||
          id == "PRECONEG" ||
          id == "IMC_ITEM")
      ) {
        return formatValue("decimal", value);
      } else if (
        value !== null &&
        (id == "VLRTAB" ||
          id == "VLRTOTAL" ||
          id == "PRECOMAX" ||
          id == "PRECOMIN" ||
          id == "PRECOMIN")
      ) {
        return formatValue("currency", value);
      } else {
        return value;
      }
    } else {
      if (id == "QTDNEG" || id == "PERCRCA" || id == "PRECONEG") {
        // //console.log('dentro do if:', id);
        if (id == "PERCRCA") {
          return (
            <RInput
              type="number"
              id={`input_${id}-${index}`}
              name={`${id}-${index}`}
              value={value}
              onChange={onChange}
              min={0}
              max={data[index].PERCRCAMAX}
              onBlur={onBlur}
              style={{ maxWidth: "3rem", padding: "8px 2px" }}
            />
          );
        } else if (id == "PRECONEG") {
          return (
            <RInput
              type="number"
              id={`input_${id}-${index}`}
              name={`${id}-${index}`}
              value={value}
              onChange={onChange}
              min={data[index].PRECOMIN}
              max={data[index].PRECOMAX}
              onBlur={onBlur}
              style={{ maxWidth: "4rem", padding: "8px 2px" }}
              ref={(el) => (refs.current[index] = el)}
            />
          );
        } else {
          return (
            <RInput
              type="number"
              id={`input_${id}-${index}`}
              name={`${id}-${index}`}
              value={value != "" ? Number(value) : value}
              onChange={onChange}
              min={0}
              onBlur={onBlur}
              style={{ maxWidth: "4rem", padding: "8px 2px" }}
            />
          );
        }
      } else {
        // //console.log('fora do if:', id);
        if (
          value !== null &&
          (id == "VLRTAB" ||
            id == "VLRTOTAL" ||
            id == "PRECOMAX" ||
            id == "PRECOMIN" ||
            id == "PRECOMIN")
        ) {
          // return <>R${value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</>
          // return <>R${value}</>
          return formatValue("currency", value);
        } else if (id == "IMC_ITEM") {
          return (
            <>
              {data[index].updated ? (
                <Button
                  className="btn-icon btn-round"
                  color="primary"
                  size="sm"
                  onClick={handleSubmit}
                >
                  <i className="fa fa-sync"></i>
                </Button>
              ) : (
                formatValue("decimal", value)
              )}
            </>
          );
        } else {
          return <>{value}</>;
        }
      }
    }
  };

  // Nosso componente de Tabela
  function Table({ columns, data, updateMyData }) {
    const filterTypes = useMemo(
      () => ({
        // Ou substitua o filtro de texto padrão para usar
        // "começar com"
        text: (rows, id, filterValue) => {
          return rows.filter((row) => {
            const rowValue = row.values[id];
            return rowValue !== undefined
              ? String(rowValue)
                  .toLowerCase()
                  .startsWith(String(filterValue).toLowerCase())
              : true;
          });
        },
      }),
      []
    );

    const defaultColumn = useMemo(
      () => ({
        // Vamos configurar nossa UI de filtro padrão
        Filter: DefaultColumnFilter,
        Cell: EditableCell,
      }),
      []
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable(
        {
          columns,
          data,
          defaultColumn, // Certifique-se de passar a opção defaultColumn
          filterTypes,
          // use a opção skipPageReset para desativar a redefinição da página temporariamente
          // autoResetPage: skipPageReset,
          // updateMyData não faz parte da API, mas
          // qualquer coisa que colocarmos nessas opções irá
          // estará automaticamente disponível na instância.
          // Dessa forma, podemos chamar esta função de nosso
          // renderizador de célula!
          updateMyData,
        },
        useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy
      );

    return (
      <>
        <Row style={{ padding: "5px", marginTop: "10px" }}>
          <Col md={3}>
            <Label for="filter_CODPROD">Cód.:</Label>
            {headerGroups[1].headers[0].render("Filter")}
          </Col>
          <Col md={3}>
            <Label for="filter_DESCRPROD">Produto:</Label>
            {headerGroups[1].headers[1].render("Filter")}
          </Col>
          <Col md={2}>
            <Label for={`produtos-listagem-m3cliente`}>M³ do Cliente:</Label>
            <RInput
              value={
                Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                }).format(m3Cliente) + " m3"
              }
              disabled
              id={`produtos-listagem-m3cliente`}
            />
          </Col>
          <Col md={2}>
            <Label for={`produtos-listagem-m3simulacao`}>
              M³ da Simulação:
            </Label>
            <RInput
              value={
                Intl.NumberFormat("pt-BR", {
                  style: "decimal",
                  currency: "BRL",
                }).format(m3Simulador) + " m3"
              }
              disabled
              id={`produtos-listagem-m3simulacao`}
            />
          </Col>
          <Col md={2}>
            <Label for={`produto-listagem-valor-faturado`}>
              Valor Faturado:
            </Label>
            <RInput
              value={Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(vlrFaturado)}
              disabled
              id={`produto-listagem-valor-faturado`}
            />
          </Col>
        </Row>
        <RTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, idxr) => (
              <tr key={idxr} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, idxc) => (
                  <th
                    key={idxc}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {/* Renderizar a IU do filtro de colunas */}
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, idxr) => {
              prepareRow(row);
              return (
                <tr key={idxr} {...row.getRowProps()}>
                  {row.cells.map((cell, idxc) => {
                    return (
                      <td
                        key={idxc}
                        {...cell.getCellProps()}
                        style={{ fontSize: "11.5px" }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </RTable>
      </>
    );
  }
  return (
    <>
      <ModalLoading
        loading={modalLoading}
        mensagem={"Carregando informações dos produtos"}
      />

      <Col>
        <Table columns={columns} data={data} updateMyData={updateMyData} />
      </Col>
    </>
  );
}

export default ProdutosListagem;
